
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Components/Header/Header';
import LandingPage from './Components/Landing_page/Landing_page';
import Footer from './Components/Footer/Footer';
import { Toaster } from 'react-hot-toast';
import Upper_header from './Components/Upper_header/Upper_header';
import Market_page from './Components/Market_page/Market_page';
import { Route, Routes } from 'react-router-dom';
function App() {
  return (
    <div className="App">
    <Upper_header/>
    <Header/>
    <Toaster />
     {/* <LandingPage/> */}
     {/* <Market_page/> */}
     <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="market" element={<Market_page />} />
      </Routes>
     {/* <Footer/>   */}
    </div>
  );
}

export default App;
