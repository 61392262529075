import React, { useEffect, useState } from "react";
import "./Landing_page.css";
import pef from "../Assets/pacfinance.jpeg";
import par from "../Assets/particle.svg";
import hyp from "../Assets/hyperlock.png";
import thr from "../Assets/thruster.png"
import fan from "../Assets/fantasy.png"
import bla from "../Assets/blasr.svg"
import build from "../Assets/build.svg"
import { Link } from "react-router-dom";

export default function Landing_page() {
  return (
    <div className="main_landing_page_here">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="upper_landing_box">
              <div className="d-flex align-items-center gap-4 ">
                <div>
                  <img
                    src={pef}
                    style={{
                      width: "64px",
                      height: "64px ",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                </div>
                <div>
                  <h1 className="landing_head site_font">
                    PAC POINTS <br /> TRADING NOW LIVE
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h1 className="points_market site_font mt-5">Points Market</h1>

        <div className="row mt-3">
          <div className="col-md-4">
          <Link to='/market' className="text-decoration-none">
            <div className="landcard">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2 align-items-center">
                  <img
                    src={pef}
                    style={{
                      width: "32px",
                      height: "32px ",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                  <div>
                    <h6 className="lc_head mb-0 site_font">Pac Points</h6>
                    <a
                      href="https://app.pac.finance"
                      className="lc_link mb-0 site_font"
                    >
                      https://app.pac.finance
                    </a>
                  </div>
                </div>
                <div className="lc_right_box">
                  <p className="m_status mb-0 site_font">Market Status</p>
                  <h6 className="status mb-0 site_font">Open</h6>
                </div>
              </div>
              <div className="mt-5">
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Launch Date</p>
                  <p className="info_tm site_font mb-0">5 days ago</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Total Volume</p>
                  <p className="info_tm site_font mb-0">2,338.75</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">TVL</p>
                  <p className="info_tm site_font mb-0">1,538.75</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Total Transactions</p>
                  <p className="info_tm site_font mb-0">28</p>
                </div>
              </div>
            </div>
            </Link>
          </div>
          <div className="col-md-4 mt-2 mt-md-0">
          <Link to='/market' className="text-decoration-none">
            <div className="landcard">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2 align-items-center">
                  <img
                    src={par}
                    style={{
                      width: "32px",
                      height: "32px ",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                  <div>
                    <h6 className="lc_head mb-0 site_font">Particle Points</h6>
                    <a
                      href="https://particle.trade"
                      className="lc_link mb-0 site_font"
                    >
                      https://particle.trade
                    </a>
                  </div>
                </div>
                <div className="lc_right_box">
                  <p className="m_status mb-0 site_font">Market Status</p>
                  <h6 className="status mb-0 site_font">Open</h6>
                </div>
              </div>
              <div className="mt-5">
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Launch Date</p>
                  <p className="info_tm site_font mb-0">7 days ago</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Total Volume</p>
                  <p className="info_tm site_font mb-0">3,338.75</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">TVL</p>
                  <p className="info_tm site_font mb-0">2,538.75</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Total Transactions</p>
                  <p className="info_tm site_font mb-0">18</p>
                </div>
              </div>
            </div>
            </Link>
          </div>
          <div className="col-md-4 mt-2 mt-md-0">
          <Link to='/market' className="text-decoration-none">
            <div className="landcard">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2 align-items-center">
                  <img
                    src={hyp}
                    style={{
                      width: "32px",
                      height: "32px ",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                  <div>
                    <h6 className="lc_head mb-0 site_font">Hyper Points</h6>
                    <a
                      href="https://hyperlock.finance"
                      className="lc_link mb-0 site_font"
                    >
                      https://hyperlock.finance
                    </a>
                  </div>
                </div>
                <div className="lc_right_box">
                  <p className="m_status mb-0 site_font">Market Status</p>
                  <h6 className="status mb-0 site_font">Open</h6>
                </div>
              </div>
              <div className="mt-5">
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Launch Date</p>
                  <p className="info_tm site_font mb-0">14 days ago</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Total Volume</p>
                  <p className="info_tm site_font mb-0">5,338.75</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">TVL</p>
                  <p className="info_tm site_font mb-0">2,538.75</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Total Transactions</p>
                  <p className="info_tm site_font mb-0">38</p>
                </div>
              </div>
            </div>
            </Link>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4">
          <Link to='/market' className="text-decoration-none">
            <div className="landcard">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2 align-items-center">
                  <img
                    src={thr}
                    style={{
                      width: "32px",
                      height: "32px ",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                  <div>
                    <h6 className="lc_head mb-0 site_font">Thruster Credits</h6>
                    <a
                      href="https://thruster.finance"
                      className="lc_link mb-0 site_font"
                    >
                      https://thruster.finance
                    </a>
                  </div>
                </div>
                <div className="lc_right_box">
                  <p className="m_status mb-0 site_font">Market Status</p>
                  <h6 className="status mb-0 site_font">Open</h6>
                </div>
              </div>
              <div className="mt-5">
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Launch Date</p>
                  <p className="info_tm site_font mb-0">5 days ago</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Total Volume</p>
                  <p className="info_tm site_font mb-0">2,338.75</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">TVL</p>
                  <p className="info_tm site_font mb-0">1,538.75</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Total Transactions</p>
                  <p className="info_tm site_font mb-0">28</p>
                </div>
              </div>
            </div>
            </Link>
          </div>
          <div className="col-md-4 mt-2 mt-md-0">
          <Link to='/market' className="text-decoration-none">
            <div className="landcard">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2 align-items-center">
                  <img
                    src={fan}
                    style={{
                      width: "32px",
                      height: "32px ",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                  <div>
                    <h6 className="lc_head mb-0 site_font">FAN Points</h6>
                    <a
                      href="https://fantasy.top"
                      className="lc_link mb-0 site_font"
                    >
                      https://fantasy.top
                    </a>
                  </div>
                </div>
                <div className="lc_right_box">
                  <p className="m_status mb-0 site_font">Market Status</p>
                  <h6 className="status mb-0 site_font">Open</h6>
                </div>
              </div>
              <div className="mt-5">
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Launch Date</p>
                  <p className="info_tm site_font mb-0">21 days ago</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Total Volume</p>
                  <p className="info_tm site_font mb-0">5,338.75</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">TVL</p>
                  <p className="info_tm site_font mb-0">3,538.75</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Total Transactions</p>
                  <p className="info_tm site_font mb-0">40</p>
                </div>
              </div>
            </div>
            </Link>
          </div>
          <div className="col-md-4 mt-2 mt-md-0">
          <Link to='/market' className="text-decoration-none">
            <div className="landcard">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2 align-items-center">
                  <img
                    src={bla}
                    style={{
                      width: "32px",
                      height: "32px ",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                  <div>
                    <h6 className="lc_head mb-0 site_font">Blast Points</h6>
                    <a
                      href="https://blast.io"
                      className="lc_link mb-0 site_font"
                    >
                      https://blast.io
                    </a>
                  </div>
                </div>
                <div className="lc_right_box">
                  <p className="m_status mb-0 site_font">Market Status</p>
                  <h6 className="status mb-0 site_font">Open</h6>
                </div>
              </div>
              <div className="mt-5">
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Launch Date</p>
                  <p className="info_tm site_font mb-0">10 days ago</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Total Volume</p>
                  <p className="info_tm site_font mb-0">8,338.75</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">TVL</p>
                  <p className="info_tm site_font mb-0">5,538.75</p>
                </div>
                <div className="lc_content my-1 d-flex justify-content-between align-items-center">
                  <p className="info_lc site_font mb-0">Total Transactions</p>
                  <p className="info_tm site_font mb-0">50</p>
                </div>
              </div>
            </div>
            </Link>
          </div>
        </div>
      </div>
      <img src={build} className="btn_build d-none d-lg-block" alt="" />
    </div>
  );
}
