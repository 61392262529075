export const Data=[
    {
        pricePoint:"$0.025",
        image:"",
        amount:"20,000",
        for:"0.001",
        type:"Partial",
        priceParPoint:"$0.025",
        Amount2:"1,450,379.108",
        for2:"1",
        type2:"Partial",
    },
    {
        pricePoint:"$0.027",
        image:"",
        amount:"134,527,432",
        for:"0.01",
        type:"Partial",
        priceParPoint:"$0.027",
        Amount2:"1,450,379.108",
        for2:"0.01",
        type2:"Partial",
    },
    {
        pricePoint:"$0.029",
        image:"",
        amount:"10,000",
        for:"0.02",
        type:"Partial",
        priceParPoint:"$0.035",
        Amount2:"1,450,379.108",
        for2:"0.02",
        type2:"Partial",
    },
    {
        pricePoint:"$0.031",
        image:"",
        amount:"1,450,379.108",
        for:"0.05",
        type:"Partial",
        priceParPoint:"$0.039",
        Amount2:"1,450,379.108",
        for2:"0.05",
        type2:"Partial",
    }
    ,
    {
        pricePoint:"$0.034",
        image:"",
        amount:"50,379.108",
        for:"1",
        type:"Partial",
        priceParPoint:"$0.043",
        Amount2:"1,450,379.108",
        for2:"1",
        type2:"Partial",
    },
    {
        pricePoint:"$0.039",
        image:"",
        amount:"50,379.108",
        for:"2",
        type:"Partial",
        priceParPoint:"$0.043",
        Amount2:"1,450,379.108",
        for2:"1",
        type2:"Partial",
    },
    {
        pricePoint:"$0.045",
        image:"",
        amount:"540,379.108",
        for:"5",
        type:"Partial",
        priceParPoint:"$0.043",
        Amount2:"1,450,379.108",
        for2:"1",
        type2:"Partial",
    },
  
]