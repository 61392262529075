import React, { useState } from "react";
import "./Market_page.css";
import pec from "../Assets/pacfinance.jpeg";
import fan from "../Assets/fantasy.png";
import bas from "../Assets/blasr.svg";
import { GoArrowUpRight } from "react-icons/go";
import { Data } from "./Data";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
} from "@wagmi/core";
import { Pump_Presale_Abi, Pump_Presale_Address } from "../../utilies/constant";
import toast from "react-hot-toast";

export default function Market_page() {
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();
  const { address } = useAccount();
  const [spinner, setspinner] = useState(false);
  const [selectIndex, setselectIndex] = useState(0);

  const USDTapproveSubmit = async (items) => {
    try {
      setspinner(true);
      let values = items?.for * 1000000000000000000;
      const { request } = await prepareWriteContract({
        address: Pump_Presale_Address,
        abi: Pump_Presale_Abi,
        functionName: "BuyWithETH",
        args: [],
        value: values.toString(),
        account: address,
      });
      const { hash } = await writeContract(request);
      const data = await waitForTransaction({
        hash,
      });
      setTimeout(() => {
        toast.success(`Transtion Successfull.`);
        setspinner(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setspinner(false);
    }
  };

  return (
    <div className="main_market_page">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8">
            <div className="d-flex flex-column flex-lg-row gap-3">
              <div className="m_bg d-flex justify-content-between">
                <div className="d-flex gap-2 aling-items-center">
                  <img
                    src={pec}
                    style={{
                      width: "42px",
                      height: "42px ",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />

                  <div>
                    <h6 className="p_points mb-0 site_font">Pac Points</h6>
                    <a
                      href="https://app.pac.finance"
                      className="lc_link mb-0 site_font"
                    >
                      https://app.pac.finance
                    </a>
                  </div>
                </div>
                <div className="text-end">
                  <p className="mm_st mb-0">status</p>
                  <div class="dropdown">
                    <button
                      class="btn new_drop  dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      open
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <div className="d-flex justify-content-between ">
                        <div className="d-flex align-items-center gap-1">
                          <img
                            src={pec}
                            style={{
                              width: "42px",
                              height: "42px ",
                              borderRadius: "50%",
                            }}
                            alt=""
                          />
                          <div>
                            <h6 className="p_points mb-0 site_font">
                              Pac Points
                            </h6>
                            <a
                              href="https://app.pac.finance"
                              className="lc_link mb-0 site_font"
                            >
                              https://app.pac.finance
                            </a>
                          </div>
                        </div>
                        <div>
                          <p className="mm_st site_font mb-0">status</p>
                          <button className="open_st">open</button>
                        </div>
                      </div>
                      <div className="d-flex my-2 justify-content-between ">
                        <div className="d-flex align-items-center gap-1">
                          <img
                            src={pec}
                            style={{
                              width: "42px",
                              height: "42px ",
                              borderRadius: "50%",
                            }}
                            alt=""
                          />
                          <div>
                            <h6 className="p_points mb-0 site_font">
                              Pac Points
                            </h6>
                            <a
                              href="https://app.pac.finance"
                              className="lc_link mb-0 site_font"
                            >
                              https://app.pac.finance
                            </a>
                          </div>
                        </div>
                        <div>
                          <p className="mm_st site_font mb-0">status</p>
                          <button className="open_st">open</button>
                        </div>
                      </div>{" "}
                      <div className="d-flex my-2 justify-content-between ">
                        <div className="d-flex align-items-center gap-1">
                          <img
                            src={pec}
                            style={{
                              width: "42px",
                              height: "42px ",
                              borderRadius: "50%",
                            }}
                            alt=""
                          />
                          <div>
                            <h6 className="p_points mb-0 site_font">
                              Pac Points
                            </h6>
                            <a
                              href="https://app.pac.finance"
                              className="lc_link mb-0 site_font"
                            >
                              https://app.pac.finance
                            </a>
                          </div>
                        </div>
                        <div>
                          <p className="mm_st site_font mb-0">status</p>
                          <button className="open_st">open</button>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="d-flex gap-3 my-2 my-md-0 align-items-center">
                <div>
                  <h6 className="lprice mb-0 site_font">Last Price</h6>
                  <p className="text-white site_font mb-0">$0.0₃249</p>
                </div>
                <div>
                  <h6 className="lprice mb-0 site_font">24h Vol</h6>
                  <p className="text-white site_font mb-0">$0.0₃249</p>
                </div>
                <div>
                  <h6 className="lprice mb-0 site_font">Total Vol</h6>
                  <p className="text-white site_font mb-0">$0.0₃249</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="d-flex gap-3 ">
              <div>
                <h6 className="lprice mb-0 site_font">Settlement Starts</h6>
                <p className="text-white site_font mb-0">TBA</p>
              </div>
              <div>
                <h6 className="lprice mb-0 site_font">Settlement Ends</h6>
                <p className="text-white site_font mb-0">TBA</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4 ">
          <div className="col-md-6 pe-0">
            <div className="table-responsive">
              <table className="table table-dark ">
                <thead>
                  <tr>
                    <th className="tb_header" scope="col">
                      Price per Point
                    </th>
                    <th className="tb_header" scope="col">
                      Amount
                    </th>
                    <th className="tb_header" scope="col">
                      For
                    </th>
                    <th className="tb_header" scope="col">
                      Type
                    </th>
                    <th className="tb_header" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {Data.map((items, index) => {
                    return (
                      <>
                        <tr>
                          <th scope="row">
                            <div className="d-flex align-items-center first_cell site_font ">
                              {items.pricePoint}
                              <img
                                src={bas}
                                style={{ width: "16px", height: "16px" }}
                                alt=""
                              />
                            </div>
                          </th>
                          <td>
                            <div className="d-flex align-items-center text-white seb_cell site_font">
                              <img
                                src={bas}
                                style={{ width: "16px", height: "16px" }}
                                alt=""
                              />
                              {items.amount}
                            </div>
                          </td>
                          <td>
                            <div className=" align-items-center    site_font">
                              <p className="seb_cell mb-0">
                                {" "}
                                <img
                                  src={bas}
                                  style={{ width: "16px", height: "16px" }}
                                  alt=""
                                />{" "}
                                {items.for} Eth
                              </p>{" "}
                              <p className="first_cell mb-0 fw-bold  ">
                                {items.for} Eth
                              </p>
                            </div>
                          </td>
                          <td>
                            <span className="tb_parti site_font ">
                              {items.type}
                            </span>
                          </td>
                          <td>
                            <button
                              className="tb_btn_b_s"
                              onClick={() => (
                                USDTapproveSubmit(items), setselectIndex(index)
                              )}
                            >
                              {selectIndex == index && spinner == true
                                ? "Loading..."
                                : "buy"}
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-6 ps-0">
            <div className="table-responsive">
              <table className="table table-dark ">
                <thead>
                  <tr>
                    <th className="tb_header" scope="col">
                      Price per Point
                    </th>
                    <th className="tb_header" scope="col">
                      Amount
                    </th>
                    <th className="tb_header" scope="col">
                      For
                    </th>
                    <th className="tb_header" scope="col">
                      Type
                    </th>
                    <th className="tb_header" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {Data.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <th scope="row">
                            <div className="d-flex align-items-center sec_tb_first_cell site_font ">
                              {item?.priceParPoint}
                              <img
                                src={bas}
                                style={{ width: "16px", height: "16px" }}
                                alt=""
                              />
                            </div>
                          </th>
                          <td>
                            <div className="d-flex align-items-center text-white seb_cell site_font">
                              <img
                                src={bas}
                                style={{ width: "16px", height: "16px" }}
                                alt=""
                              />
                              {item.Amount2}
                            </div>
                          </td>
                          <td>
                            <div className=" align-items-center    site_font">
                              <p className="seb_cell mb-0">
                                {" "}
                                <img
                                  src={bas}
                                  style={{ width: "16px", height: "16px" }}
                                  alt=""
                                />{" "}
                                {item.for2} Eth
                              </p>{" "}
                              <p className="sec_tb_first_cell mb-0 fw-bold  ">
                                {item.for2} Eth
                              </p>
                            </div>
                          </td>
                          <td>
                            <span className="tb_parti site_font ">Partial</span>
                          </td>
                          <td>
                            <button className="tb_btn_b_s">Sell</button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-8">
            <div className="table_upper_">
              <h6 className="fs-6 site_font mb-0 text-white ">Recent Trades</h6>

              <div class="dropdown">
                <button
                  class="btn new_drop  dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  All
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li className="site_font fs-6 text-white my-2">All</li>
                  <li className="site_font fs-6 text-white my-2">Buy</li>
                  <li className="site_font fs-6 text-white my-2">Sell</li>
                </ul>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-dark ">
                <thead>
                  <tr>
                    <th className="tb_header" scope="col">
                      Time
                    </th>
                    <th className="tb_header" scope="col">
                      {" "}
                      Type
                    </th>
                    <th className="tb_header" scope="col">
                      Pair
                    </th>
                    <th className="tb_header" scope="col">
                      Price Per Point
                    </th>
                    <th className="tb_header" scope="col">
                      For
                    </th>
                    <th className="tb_header" scope="col">
                      Filled Amount
                    </th>
                    <th className="tb_header" scope="col">
                      {" "}
                      TX
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" className="days_ago">
                      2 days ago
                    </th>
                    <td>
                      <span className="lower_bu">Buy</span>
                    </td>
                    <td>Pac Points/USDB</td>
                    <td>
                      <p className="mb-0 site_font text-white ">
                        $0.030 /{" "}
                        <img
                          src={pec}
                          style={{ width: "16px", height: "16px " }}
                          alt=""
                        />
                      </p>
                    </td>
                    <td>
                      <p className="mb-0 site_font text-white ">
                        309.381
                        <img
                          src={pec}
                          style={{ width: "16px", height: "16px " }}
                          alt=""
                        />
                      </p>
                    </td>
                    <td>
                      <p className="mb-0 site_font text-white ">
                        9,000
                        <img
                          src={pec}
                          style={{ width: "16px", height: "16px " }}
                          alt=""
                        />
                      </p>
                    </td>
                    <td>
                      <span className="up_right_arr">
                        <GoArrowUpRight />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="days_ago">
                      2 days ago
                    </th>
                    <td>
                      <span className="lower_bu">Buy</span>
                    </td>
                    <td>Pac Points/USDB</td>
                    <td>
                      <p className="mb-0 site_font text-white ">
                        $0.030 /{" "}
                        <img
                          src={pec}
                          style={{ width: "16px", height: "16px " }}
                          alt=""
                        />
                      </p>
                    </td>
                    <td>
                      <p className="mb-0 site_font text-white ">
                        309.381
                        <img
                          src={pec}
                          style={{ width: "16px", height: "16px " }}
                          alt=""
                        />
                      </p>
                    </td>
                    <td>
                      <p className="mb-0 site_font text-white ">
                        9,000
                        <img
                          src={pec}
                          style={{ width: "16px", height: "16px " }}
                          alt=""
                        />
                      </p>
                    </td>
                    <td>
                      <span className="up_right_arr">
                        <GoArrowUpRight />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="days_ago">
                      2 days ago
                    </th>
                    <td>
                      <span className="lower_bu">Buy</span>
                    </td>
                    <td>Pac Points/USDB</td>
                    <td>
                      <p className="mb-0 site_font text-white ">
                        $0.030 /{" "}
                        <img
                          src={pec}
                          style={{ width: "16px", height: "16px " }}
                          alt=""
                        />
                      </p>
                    </td>
                    <td>
                      <p className="mb-0 site_font text-white ">
                        309.381
                        <img
                          src={pec}
                          style={{ width: "16px", height: "16px " }}
                          alt=""
                        />
                      </p>
                    </td>
                    <td>
                      <p className="mb-0 site_font text-white ">
                        9,000
                        <img
                          src={pec}
                          style={{ width: "16px", height: "16px " }}
                          alt=""
                        />
                      </p>
                    </td>
                    <td>
                      <span className="up_right_arr">
                        <GoArrowUpRight />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-md-4">
            <div className="table_upper_">
              <h6 className="fs-6 site_font mb-0 text-white ">
                {" "}
                Your Recent Trades
              </h6>

              <div class="dropdown">
                <button
                  class="btn new_drop  dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  All
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li className="site_font fs-6 text-white my-2">All</li>
                  <li className="site_font fs-6 text-white my-2">Buy</li>
                  <li className="site_font fs-6 text-white my-2">Sell</li>
                </ul>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-dark ">
                <thead>
                  <tr>
                    <th className="tb_header" scope="col">
                      Time
                    </th>
                    <th className="tb_header" scope="col">
                      {" "}
                      Filled Amount
                    </th>
                    <th className="tb_header" scope="col">
                      Deposited
                    </th>
                    <th className="tb_header" scope="col">
                      Type
                    </th>
                  </tr>
                </thead>
                <tbody className="without_contet">
                  {/* <tr>
                    <th scope="row" className="days_ago">2 days ago</th>
                    <td>
                      <span className="lower_bu">Buy</span>
                    </td>
                    <td>Pac Points/USDB</td>
                    <td>
                      <p className="mb-0 site_font text-white ">
                        $0.030 /{" "}
                        <img
                          src={pec}
                          style={{ width: "16px", height: "16px " }}
                          alt=""
                        />
                      </p>
                    </td>

                  </tr> */}
                  <tr class="border-b transition-colors hover:bg-zinc-100/50 data-[state=selected]:bg-zinc-100">
                    <td
                      class="align-middle [&amp;:has([role=checkbox])]:pr-0 text-center p-0"
                      colspan="4"
                    >
                      <div class="py-4 flex justify-center gap-1 text-main-white clig-liga-off font-urbanist text-xs md:text-sm font-medium leading-normal">
                        No Recent Trades.
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
