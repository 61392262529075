import React from "react";
import "./Upper_header.css";

export default function Upper_header() {
  return (
    <div className="upper_header">
      <p className="text-center mb-0 up_par">Start trading now to receive Pump Points, Blast Gold and </p>
    </div>
  );
}
