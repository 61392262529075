import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'

import { BrowserRouter } from 'react-router-dom';
import { WagmiConfig } from 'wagmi'
import { defineChain } from 'viem';

export const LOOP = defineChain({
  id: 81457, //238 //168587773
  name: 'Blast Sepolia Testnet',
  network: 'Blast Sepolia Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Blast Sepolia Testnet',
    symbol: 'ETH',
  },
  rpcUrls: {

    default: {
      http: ['https://rpc.blast.io'],
      webSocket: ['https://rpc.blast.iog'],
    },
    public: {
      http: ['https://rpc.blast.io'],
      webSocket: ['https://rpc.blast.io'],
    },
  },

  blockExplorers: {
    default: { name: 'Explorer', url: 'https://blastscan.io/' },
  },

})
// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '48d3095fd255faa9fab9de419e4464b0'

// 2. Create wagmiConfig
const metadata = {
  name: 'Otc Pump Market',
  description: 'Otc Pump Market',
  url: 'https://otc.pump.market',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}


const chains = [LOOP]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <BrowserRouter>
    <WagmiConfig config={wagmiConfig}>
      <App />
    </WagmiConfig>
   </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
